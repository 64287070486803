<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { Line } from '@/vue-chartjs'

export default {
  extends: Line,
  props: {
    date: Date,
    mode: String
  },
  data() {
    return {
      isUpdating: false,
      noData: false,
      options: {
        animation: {
          duration: 0
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        elements: {
          line: {
            borderColor: '#00cba9',
            borderWidth: 2
          },
          point: {
            radius: 0
          }
        },
        tooltips: {
          enabled: false
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'linear',
              display: false,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ],
          yAxes: [
            {
              ticks: {
                min: undefined,
                max: undefined
              },
              display: false,
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    ...mapGetters('entries', ['lastEntry', 'entryDates', 'getEntryByDate']),
    ...mapState('entries', ['entries']),
    ...mapGetters('currencies', ['activeCurrencyWithSubunit'])
  },
  asyncComputed: {
    async chartdata() {
      this.isUpdating = true
      this.noData = false
      const { mode, activeCurrencyWithSubunit, entryDates } = this
      const lastEntry = this.lastEntry(this.date)
      if (!lastEntry) {
        this.isUpdating = false
        this.noData = true
        return null
      }
      const idx = entryDates.indexOf(lastEntry.date)
      const startIdx = Math.max(0, idx - 5)
      const dates = entryDates.slice(startIdx, idx + 1)
      if (dates.length < 2) {
        this.isUpdating = false
        this.noData = true
        return null
      }
      const entries = dates.map(date => this.getEntryByDate(date))
      let minY = null
      let maxY = null
      const dataPromises = entries.map(async entry => {
        const rate = await this.getExchangeRateOn({
          currency: activeCurrencyWithSubunit,
          date: entry.date
        })
        let y = 0
        if (mode === 'networth') {
          y = entry.netWorth * rate
        } else if (mode === 'assets') {
          y = entry.netAssets * rate
        } else if (mode === 'liabilities') {
          y = entry.netLiabilities * rate
        }
        if (minY === null) {
          minY = y
        } else {
          minY = Math.min(minY, y)
        }
        if (maxY === null) {
          maxY = y
        } else {
          maxY = Math.max(maxY, y)
        }
        return {
          t: entry.date,
          y
        }
      })
      const data = await Promise.all(dataPromises)
      this.isUpdating = false
      this.noData = false
      return {
        datasets: [
          {
            backgroundColor: '#bbf1e8',
            data,
            minY: Math.max(0, minY * 0.75, minY - 0.5 * maxY),
            maxY: maxY * 1.01
          }
        ]
      }
    }
  },
  watch: {
    chartdata() {
      if (this.chartdata) {
        this.options.scales.yAxes[0].ticks.min = this.chartdata.datasets[0].minY
        this.options.scales.yAxes[0].ticks.max = this.chartdata.datasets[0].maxY
        this.renderChart(this.chartdata, this.options)
      }
    },
    isUpdating: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$parent.$emit('update:start')
        } else {
          this.$parent.$emit('update:done')
        }
      }
    },
    noData: {
      immediate: true,
      handler(val) {
        if (val) {
          this.$parent.$emit('nodata')
        }
      }
    }
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn'])
  }
}
</script>
