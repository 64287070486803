<template lang="pug">
section.section.entry-screen.container
  b-loading(:is-full-page="true" :active.sync="initializing" :can-cancel="false")
  .columns(v-if="!initializing && activeEntry")
    .column.is-12.header-flex
      .datepicker-with-nav
        .field.has-addons
          .control
            b-button.button(aria-label="Previous Entry" title="Previous Entry" @click="previous()" :disabled="!previousEntryOn")
              b-icon(icon="angle-left")
          .control.datepicker-control
            b-datepicker(:value="activeEntryDate" @input="changeActiveEntry" :date-parser="dateParser" :date-formatter="dateFormatter" :max-date="today" :selectable-dates="entryDates" @blur="syncActiveEntryDate")
          .control
            b-button.button(aria-label="Next Entry" title="Next Entry" @click="next()" :disabled="!nextEntryOn")
              b-icon(icon="angle-right")
      display-currency-select
      router-link.add-entry.is-primary.button(:to="{name: 'new_entry'}") Add Entry
  .info-grid(v-if="activeEntry")
    .net-worth
      .card.nw-card
        h2.card-title Net worth
        currency-output.card-value(:value="activeEntry.netWorth" :date="activeEntry.date")
        p.notes(v-if="activeEntry.note.length")
          strong Note:&nbsp;
          | {{ activeEntry.note }}
        hide-wrapper
          spark-line.spark-line(:date="activeEntry.date" mode='networth' chart-id="networth-spark")
      .card.next-goal(v-if="activeGoal")
        goal-display(:goal='activeGoal' :date="activeEntry.date")
          router-link.goal-settings(:to="{name: 'goals'}")
            b-icon(icon="cog")
      .card.set-goal(v-else)
        p Set a goal and track your progress towards it!
        b-button.is-twitter(@click="setGoal") Set a Goal
    .card.net-assets
      h2.card-title Assets
      currency-output.card-value(:value="activeEntry.netAssets" :date="activeEntry.date")
      hide-wrapper
        spark-line.spark-line(:date="activeEntry.date" mode='assets' chart-id="netassets-spark")
    .card.net-liabilities
      h2.card-title Liabilities
      currency-output.card-value(:value="activeEntry.netLiabilities" :date="activeEntry.date")
      hide-wrapper
        spark-line.spark-line(:date="activeEntry.date" mode='liabilities' chart-id="netliabilities-spark")
  .comparisons(v-if="activeEntry && comparativeEntries.length")
    h2.section-title Progress since
    .comparison-grid
      router-link.comparison.card(v-for="comparison in comparativeEntries" :key="comparison.id" :to="{ query: { date: comparison.id }}")
        .comparison-name(v-if="comparison.isLastEntry") Previous entry
        .comparison-name(v-else) {{ dateFormatter(comparison.date) }}
        currency-output.comparison-value(:value="comparison.netWorth" :date="comparison.date")
        currency-output.comparison-delta(:value="comparison.delta" :date="activeEntry.date" :class='{positive: comparison.delta >= 0}')
  .holdings(v-if="activeEntry")
    h2.section-title.holdings-title
      span.text Holdings as of {{ dateFormatter(activeEntry.date) }}
      sort-changer
      router-link.button(:to="{name: 'edit_entry', params: { entryId: activeEntry.id }}") Edit
    draggable.holdings-grid(v-model="sortedHoldings" :scroll-speed="20" :scroll-sensitivity="50" :disabled="categoryOrder !== 'user'" group="holdings" ghost-class="ghost" :class="{dnd: categoryOrder == 'user'}")
      holding-card.asset(v-for="holding in entryAssets" :key="holding.categoryId" :holding="holding" :entry="activeEntry" :previous="previousEntry")
      holding-card.liability(v-for="holding in entryLiabilities" :key="holding.categoryId" :holding="holding" :entry="activeEntry" :previous="previousEntry")
  b-modal(:active.sync="showNewGoal" has-modal-card :can-cancel="['escape']" :trap-focus="true")
    goal-form-dialog
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { sortBy } from 'lodash'
import Draggable from 'vuedraggable'
import dayjs from '@/dayjs'

import { dateParser } from '@/misc/helpers'
import { formatDate } from '@/misc/filters'

import DisplayCurrencySelect from '@/components/DisplayCurrencySelect'
import HideWrapper from '@/components/HideWrapper'
import SparkLine from '@/components/charts/SparkLine'
import GoalFormDialog from '@/components/GoalFormDialog'
import GoalDisplay from '@/components/GoalDisplay'
import SortChanger from '@/components/SortChanger'
import HoldingCard from '@/components/HoldingCard'

export default {
  name: 'EntriesView',
  components: {
    Draggable,
    DisplayCurrencySelect,
    HideWrapper,
    SparkLine,
    GoalDisplay,
    GoalFormDialog,
    SortChanger,
    HoldingCard
  },
  data() {
    return {
      today: dayjs()
        .startOf('day')
        .toDate(),
      activeEntry: null,
      activeEntryDate: null,
      comparativeEntries: [],
      showNewGoal: false
    }
  },
  computed: {
    ...mapState('currencies', ['activeCurrency', 'loadingCurrencies']),
    ...mapState('entries', ['entries', 'loadingEntries']),
    ...mapGetters('entries', ['entryDates', 'lastEntry', 'getEntryByDate']),
    ...mapState('categories', ['loadingCategories']),
    ...mapState('goals', ['loadingGoals']),
    ...mapGetters('goals', ['getActiveGoal']),
    ...mapGetters('authentication', ['categoryOrder']),
    initializing() {
      return (
        this.loadingCurrencies ||
        this.loadingEntries ||
        this.loadingCategories ||
        this.loadingGoals
      )
    },
    activeGoal() {
      const { activeEntry } = this
      if (activeEntry) {
        return this.getActiveGoal(activeEntry.date)
      }
      return null
    },
    previousEntry() {
      const { activeEntry, entryDates, getEntryByDate } = this
      if (!activeEntry) {
        return null
      }
      const idx = entryDates.indexOf(activeEntry.date)
      if (!idx) {
        return null
      }
      return getEntryByDate(entryDates[idx - 1])
    },
    previousEntryOn() {
      const { previousEntry } = this
      if (!previousEntry) {
        return null
      }
      return previousEntry.date
    },
    nextEntryOn() {
      const { activeEntry, entryDates } = this
      if (!activeEntry) {
        return null
      }
      const idx = entryDates.indexOf(activeEntry.date)
      if (idx < entryDates.length - 1) {
        return entryDates[idx + 1]
      }
      return null
    },
    entryAssets() {
      const { activeEntry } = this
      if (!activeEntry) {
        return []
      }
      return sortBy(activeEntry.assets, ({ categoryId }) =>
        this.$store.getters['categories/categoryWeight'](categoryId)
      )
    },
    entryLiabilities() {
      const { activeEntry } = this
      if (!activeEntry) {
        return []
      }
      return sortBy(activeEntry.liabilities, ({ categoryId }) =>
        this.$store.getters['categories/categoryWeight'](categoryId)
      )
    },
    sortedHoldings: {
      get() {
        const { entryAssets = [], entryLiabilities = [] } = this
        return entryAssets
          .concat(entryLiabilities)
          .map(({ categoryId }) => categoryId)
      },
      set(holdingOrder) {
        const getCategoryById = this.$store.getters[
          'categories/getCategoryById'
        ]
        const sortedCategories = sortBy(
          holdingOrder.map(id => getCategoryById(id)),
          ({ isAsset }) => (isAsset ? 0 : 1)
        )
        this.saveOrder(sortedCategories)
      }
    }
  },
  watch: {
    '$route.query.date': function() {
      this.loadActiveEntry()
    },
    initializing() {
      this.loadActiveEntry()
    }
  },
  mounted() {
    this.loadActiveEntry()
  },
  methods: {
    ...mapActions('currencies', ['setDisplayCurrency', 'getExchangeRateOn']),
    ...mapActions('categories', ['saveOrder']),
    addEntry() {
      this.$router.push({ name: 'new_entry' })
    },
    changeActiveEntry(date) {
      const formattedDate = dayjs(date).format('YYYY-MM-DD')
      this.syncActiveEntryDate()
      if (this.$route.query.date === formattedDate) {
        return
      }
      this.$router.push({
        query: {
          date: formattedDate
        }
      })
    },
    // This is a nasty workaround for a very annoying bug where the
    // datepicker can get out of sync with the active entry date
    syncActiveEntryDate() {
      if (this.activeEntry) {
        this.activeEntryDate = null
        this.$nextTick(() => {
          this.activeEntryDate = new Date(+this.activeEntry.date)
        })
      } else {
        this.activeEntryDate = null
      }
    },
    previous() {
      this.changeActiveEntry(this.previousEntryOn)
    },
    next() {
      this.changeActiveEntry(this.nextEntryOn)
    },
    setGoal() {
      this.showNewGoal = true
    },
    dateFormatter(date) {
      const parsedDate = dayjs(date)
      if (parsedDate.year() === this.today.getFullYear()) {
        return formatDate(parsedDate, 'noyear')
      }
      return formatDate(parsedDate, 'short')
    },
    dateParser,
    editEntry(entry) {
      this.$router.push({ name: 'edit_entry', params: { entryId: entry.id } })
    },
    loadActiveEntry() {
      const { initializing, lastEntry, today } = this
      const { date } = this.$route.query
      if (initializing) {
        return
      }
      const entry = lastEntry(date || today)
      if (entry) {
        this.activeEntry = entry
        this.syncActiveEntryDate()
      } else {
        this.$router.push({
          name: 'new_entry',
          query: { date }
        })
        this.activeEntry = null
      }
      this.loadComparativeEntries()
    },
    async loadComparativeEntries() {
      const { activeEntry, activeCurrency, entryDates } = this
      if (activeEntry) {
        // Get the first entry for each year
        const dates = new Set()
        const activeRate = await this.getExchangeRateOn({
          date: activeEntry.date,
          currency: activeCurrency.id
        })
        let currentYear = null
        entryDates.forEach(date => {
          const year = date.getFullYear()
          if (year === currentYear) {
            return
          }
          currentYear = year
          dates.add(date)
        })
        // Ensure the last entry is there
        const idx = entryDates.indexOf(activeEntry.date)
        let lastEntryDate = null
        if (idx) {
          lastEntryDate = entryDates[idx - 1]
          dates.add(lastEntryDate)
        }
        // Remove the active entry + any dates in the future
        dates.forEach(date => {
          if (dayjs(date).isSameOrAfter(activeEntry.date)) {
            dates.delete(date)
          }
        })
        const promises = Array.from(dates).map(async date => {
          const isLastEntry = date === lastEntryDate
          const entry = this.getEntryByDate(date)
          const rate = await this.getExchangeRateOn({
            date: entry.date,
            currency: activeCurrency.id
          })
          return {
            id: entry.id,
            date: entry.date,
            netWorth: entry.netWorth,
            delta:
              (activeEntry.netWorth * activeRate - entry.netWorth * rate) /
              activeRate,
            isLastEntry
          }
        })
        this.comparativeEntries = (await Promise.all(promises)).reverse()
      } else {
        this.comparativeEntries = []
      }
    }
  },
  metaInfo: {
    title: 'My Data'
  }
}
</script>
<style lang="sass" scoped>
.datepicker-with-nav
  width: 100%
  max-width: 235px
  .button
    padding-left: 0.75rem
    padding-right: 0.75rem
.add-entry
  order: 1
  padding-left: 0.75rem
  padding-right: 0.75rem
.display-currency-select
  order: 2
.datepicker ::v-deep .input
  width: 100%
  border-radius: 0 !important
.datepicker-control
  flex-grow: 1
  width: 0
.header-flex
  display: flex
  justify-content: space-between
  align-items: flex-end
  flex-wrap: wrap
  > *
    margin-bottom: 0.5rem
  ::v-deep .display-currency-select
    max-width: 100%
    flex-grow: 1
    .base-currency
      max-width: 100%
      flex-grow: 1
      &.multiselect--active
        max-width: 100%
.has-addons
  align-items: flex-end
  button, button ::v-deep span
    display: flex
    align-items: center
    justify-content: center
.label
  display: none
.card
  border-radius: 2px
  position: relative
  overflow: hidden
  .card-title
    color: hsl(120,20%,30%)
    padding: 0.75rem 1.5rem 0
  .card-value
    display: block
    font-size: 1.3rem
    padding: 0 1.5rem 0.75rem
    white-space: nowrap
    max-width: 100%
    overflow: hidden
    text-overflow: ellipsis
    + .hide-wrapper
      margin-top: -0.65rem
      position: relative
      top: 1px
.next-goal
  padding: 0.75rem 1.5rem
.notes
  padding: 0 1.5rem 0.75rem
.goal-settings
  color: #777
  font-size: 1.1rem
  &:hover
    color: #43ae43
h2.section-title
  width: 100%
  font-size: 1.1rem
  margin: 1rem 0 0.75rem
.columns
  margin-bottom: 0
.comparison-grid
  display: grid
  grid-gap: 0.5rem
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr))
  .comparison
    padding: 0.75rem 1rem
    border: 1px solid white
    display: block
    &:hover
      border: 1px solid hsl(119,26%,64%)
  .comparison-name
    color: #555
    margin-bottom: 0.25rem
  .comparison-value, .comparison-delta
    display: block
    white-space: nowrap
    max-width: 100%
    overflow: hidden
    text-overflow: ellipsis
  .comparison-value
    font-size: 1.2rem
    margin-bottom: 0.25rem
  .comparison-delta
    color: #c44b4b
    &.positive
      color: green
      &:before
        content: "+"
.holdings-title
  display: flex
  justify-content: space-between
  align-items: center
  .text
    flex-grow: 1
.set-goal
  font-size: 1rem
  padding: 1rem 1.5rem
  display: flex
  align-items: center
  justify-content: center
  .button
    margin-left: 1rem
.net-worth .hide-wrapper
  height: 4rem
  width: 100%
.net-worth .card, .net-assets, .net-liabilities
  margin-bottom: 0.5rem
.net-assets, .net-liabilities, .hide-wrapper
  display: flex
  flex-direction: column
  .spark-line, .hide-wrapper
    width: 100%
    flex-grow: 1
    height: 0
    min-height: 2rem
.hide-wrapper
  ::v-deep canvas
    border-radius: 0 0 2px 2px
.spark-line
  position: relative
.welcome-title
  margin-bottom: 1rem
.holdings-grid
  display: grid
  grid-gap: 0.5rem
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr))
  .asset
    border-top: 2px solid #43ae43
  .liability
    border-top: 2px solid #c44b4b
.dnd .holding
  cursor: pointer
.ghost
  position: relative
  border-top: 0 !important
  border-radius: 3px
  cursor: grab
  &:before
    content: " "
    position: absolute
    top: -2px
    left: 0
    right: 0
    bottom: 0
    background: #ddd
    border-radius: 3px
    z-index: 1
    box-shadow: inset 0 0 10px #aaa
@media screen and (min-width: 768px)
  .nw-card
    flex-grow: 1
    display: flex
    flex-direction: column
    justify-content: center
    .hide-wrapper
      flex-grow: 1
  .info-grid
    display: grid
    grid-template-areas: "networth assets" "networth liabilities"
    grid-template-rows: 1fr 1fr
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr)
    grid-gap: 1rem
    .net-worth
      grid-area: networth
      display: flex
      flex-direction: column
      .card
        margin-bottom: 0
        border-radius: 2px 2px 0 0
      ::v-deep canvas
        border-radius: 0
      .card.next-goal
        border-radius: 0 0 2px 2px
    .net-assets
      grid-area: assets
      margin-bottom: 0
    .net-liabilities
      grid-area: liabilities
      margin-bottom: 0
@media screen and (min-width: 550px)
  .header-flex
    flex-wrap: nowrap
    ::v-deep .multiselect
      max-width: 100px
      .prefix
        display: none
      &.multiselect--active
        max-width: 250px
  .add-entry
    margin-left: 0.5rem
    order: 3
    padding-left: 1rem
    padding-right: 1rem
  .datepicker-with-nav
    flex-grow: 1
    max-width: none
    .button
      padding-left: 1rem
      padding-right: 1rem
    .field
      max-width: 250px
  .label
    display: block
</style>
