<template lang="pug">
.hide-wrapper(:style="style" ref="wrapper")
  slot
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      height: null,
      hidden: false
    }
  },
  computed: {
    style() {
      const { isLoading, height, hidden } = this
      if (isLoading && height) {
        const style = {
          height: `${height}px`,
          opacity: 0
        }
        return style
      }
      if (hidden) {
        return { display: 'none' }
      }
      return {}
    }
  },
  mounted() {
    this.$on('update:start', () => {
      this.isLoading = true
      this.hidden = false
      this.height = this.$refs.wrapper.clientHeight
    })
    this.$on('nodata', () => {
      this.hidden = true
    })
    this.$on('update:done', () => {
      this.isLoading = false
      this.height = null
    })
  },
  beforeDestroy() {
    this.$off('update:start')
    this.$off('update:done')
  }
}
</script>
