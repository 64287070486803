<template lang="pug">
.holding.card
  .holding-name {{ getCategoryName(holding.categoryId) }}
  currency-output.holding-value(:value="currentValue" :date="entry.date")
  .actual-value(v-if="shouldShowActualAmount(holding)") {{ holding.amount | currencyFormatter(holdingCurrency) }}
  b-icon.indicator(v-if="indicator" :icon="indicatorIcon" :class="indicator" :title="indicatorTitle")
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { currencyWithSubunit } from '@/misc/helpers'
import currencyFormatter from '@/misc/currencyFormatter'

export default {
  filters: {
    currencyFormatter
  },
  props: {
    holding: Object,
    entry: Object,
    previous: Object
  },
  computed: {
    ...mapGetters('categories', ['getCategoryName', 'getCategoryById']),
    ...mapGetters('currencies', ['activeCurrencyWithSubunit']),
    holdingCurrency() {
      const { currency, subunit } = this.holding
      return currencyWithSubunit(currency, subunit)
    },
    category() {
      const { holding, getCategoryById } = this
      return getCategoryById(holding.categoryId)
    },
    previousValue() {
      const { holding, previous, category } = this
      if (!category) {
        return null
      }
      if (previous === null) {
        return null
      }
      const previousHolding = (category.isAsset
        ? previous.assets
        : previous.liabilities
      ).find(({ categoryId }) => categoryId === holding.categoryId)
      if (!previousHolding) {
        return null
      }
      return previousHolding.amount / previousHolding.baseRate
    },
    currentValue() {
      const { holding } = this
      return holding.amount / holding.baseRate
    },
    indicator() {
      const { previousValue, delta } = this
      if (previousValue === null) {
        return 'added'
      }
      if (delta === null) {
        return null
      }
      if (delta < -0.0001) {
        return 'decreased'
      }
      if (delta > 0.0001) {
        return 'increased'
      }
      return 'identical'
    },
    indicatorIcon() {
      const { indicator } = this
      if (indicator === null) {
        return null
      }
      const iconMap = {
        added: 'dot-circle',
        identical: 'minus-circle',
        increased: 'chevron-circle-up',
        decreased: 'chevron-circle-down'
      }
      return iconMap[indicator]
    },
    indicatorTitle() {
      const { delta, indicator, activeCurrencyWithSubunit } = this
      if (indicator === null) {
        return null
      }
      if (indicator === 'added') {
        return 'New holding'
      }
      if (indicator === 'identical') {
        return 'No change'
      }
      if (indicator === 'increased') {
        return `+${currencyFormatter(delta, activeCurrencyWithSubunit)}`
      }
      return currencyFormatter(delta, activeCurrencyWithSubunit)
    }
  },
  asyncComputed: {
    async delta() {
      const {
        previous,
        entry,
        previousValue,
        currentValue,
        activeCurrencyWithSubunit,
        getExchangeRateOn
      } = this
      if (previousValue === null) {
        return null
      }
      const [previousRate, currentRate] = await Promise.all([
        getExchangeRateOn({
          currency: activeCurrencyWithSubunit,
          date: previous.date
        }),
        getExchangeRateOn({
          currency: activeCurrencyWithSubunit,
          date: entry.date
        })
      ])
      const delta = currentValue * currentRate - previousValue * previousRate
      return delta
    }
  },
  methods: {
    ...mapActions('currencies', ['getExchangeRateOn']),
    shouldShowActualAmount({ currency, subunit }) {
      const { activeCurrencyWithSubunit } = this
      return (
        activeCurrencyWithSubunit !== currencyWithSubunit(currency, subunit)
      )
    }
  }
}
</script>

<style lang="sass" scoped>
.holding
  padding: 0.5rem 0.75rem
  position: relative
.holding-name
  font-size: 0.9rem
  color: #555
.holding-value, .actual-value
  font-size: 1.1rem
  display: block
  white-space: nowrap
  max-width: 100%
  overflow: hidden
  text-overflow: ellipsis
.actual-value
  font-size: .8rem
  &:before
    content: "("
  &:after
    content: ")"
.indicator
  position: absolute
  top: 0
  right: 0
  margin: 0.35rem
  &.identical
    color: grey
  &.added
    color: lightblue
.asset .indicator
  &.decreased
    color: #c44b4b
  &.increased
    color: green
.liability .indicator
  &.decreased
    color: green
  &.increased
    color: #c44b4b
</style>
